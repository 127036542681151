import React from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryLine,
  VictoryTheme
} from 'victory';

class Graph extends React.Component {

  render() {
    const {data} = this.props;

    return (
      <>
        <VictoryChart
          theme={VictoryTheme.material}
          width={600}
          height={300}
          padding={{ top: 50, bottom: 25, left: 75, right: 25 }}
          domainPadding={{ y: 40 }}
        >
          <VictoryLegend
            data={data.map(row => ({name: row.country}))}
            orientation="horizontal"
            gutter={20}
            itemsPerRow={5}
          />
          <VictoryAxis
            tickFormat={(x) => (x.toString())}
          />
          <VictoryAxis
            dependentAxis
            style={{tickLabels: {padding: 3}}}
          />
          <VictoryGroup>
            {data.map(countryData => <VictoryLine data={countryData.data} interpolation="natural" key={countryData.country} />)}
          </VictoryGroup>
        </VictoryChart>
      </>
    );
  }

}

export default Graph;
