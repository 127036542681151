import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Section1 from "./Section1";
import {CardHeader} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
});

export default function SimpleCard(props) {
  const classes = useStyles();
  const { data } = props;

  return (
    <Card className={classes.root}>
      <CardHeader title="Évolution de la population française vivant à l'étranger" />
      <Divider />
      <CardContent>
        <Section1 data={data} />
      </CardContent>
    </Card>
  );
}
