import React, {useState} from 'react';
import Graph from "./Graph";
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function Section1(props) {
  const classes = useStyles();
  const countries = Array.from(new Set(props.data.map(line => line.country)));
  const [selectedCountries, setSelectedCountries] = useState([countries[countries.length - 1]]);

  const changeCountry = (event) => {
    setSelectedCountries(event.target.value);
  };

  const extractDataByCountry = (data, selectedCountries) => {
    return data.filter(line => selectedCountries.includes(line.country)).reduce((acc, current) => {
      const currentCountry = current.country;
      const index = acc.findIndex(element => element.country === currentCountry);
      if (-1 !== index) {
        acc[index].data.push({x: current.year, y: current.number});

        return acc;
      }

      acc.push({
        country: currentCountry,
        data: [{x: current.year, y: current.number}],
      });

      return acc;
    }, []);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Graph data={extractDataByCountry(props.data, selectedCountries)} />
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel shrink id="select-multiple-native">
              Pays
            </InputLabel>
            <Select
              multiple
              label="Pays"
              value={selectedCountries}
              onChange={changeCountry}
              labelId='select-multiple-native'
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {countries && countries.map(country => (<MenuItem key={country} value={country}>{country}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
