import React, {useEffect, useState} from 'react';
import './App.css';
import AppBar from './components/AppBar';
import CardStats from "./components/CardStats";
import {Container} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CardTable from "./components/CardTable";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  summary: {
    backgroundColor: theme.palette.background.paper,
    //padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function App() {
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetch('/output.json')
      .then(res => res.json())
      .then(
        (data) => {
          data.reduce((accumulator, currentValue) => {
            const currentYear = currentValue.year;
            const index = accumulator.findIndex(line => line.year === currentYear)
            if (-1 !== index) {
              const line = accumulator[index];
              accumulator[index] = {
                ...line,
                number: currentValue.number + line.number,
              };

              return accumulator;
            }

            accumulator.push({
              country: 'MONDE ENTIER',
              year: currentYear,
              number: currentValue.number,
            });

            return accumulator;
          }, []).forEach(someData => data.push(someData))

          setData(data);
        }
      );
  }, []);

  return (
    <>
      <CssBaseline/>
      <AppBar/>
      <main>
        <div className={classes.summary}>
          <Container maxWidth="lg">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Combien de Français vivent à l'étranger ?
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Selon les dernières données fournies par le ministère des Affaires étrangères, 2,5 millions de Français vivent à l’étranger. Près de 1,8 million sont inscrits au registre des Français établis hors de France.
            </Typography>
          </Container>
        </div>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {data.length && <CardStats data={data} />}
            </Grid>
            <Grid item xs={12}>
              <CardTable data={data} />
            </Grid>
          </Grid>
        </Container>
      </main>
      <footer className={classes.footer}>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Source : <Link href='https://www.data.gouv.fr/fr/datasets/francais-de-letranger-inscriptions-au-registre-des-francais-etablis-hors-de-france/'>Ministère de l'Europe et des Affaires Etrangères (MEAE)</Link> (2013-2018)
        </Typography>
        <Typography variant="subtitle2" align="center" color="textSecondary" component="p">
          © Tous droits réservés
        </Typography>
      </footer>
    </>
  );
}

export default App;
